import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Obfuscate from "react-obfuscate";
import Seo from "../components/Seo";
import TopNav from "../components/TopNav";
import Footer from "../components/Footer";
import "../styles/privacy-policy.scss";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Seo
        title="Privacy Policy"
        description="bld.ai is committed to protecting your privacy. This Privacy Policy explains the privacy practices (data collection and usage) of bld.ai and the bld.ai Web3 Studio website."
      />
      <TopNav />
      <main id="privacy-policy-page" className="mt-n8 pt-10 pb-10">
        <Container>
          <h1 className="text-center mb-8">Privacy Policy</h1>
          <Row className="pb-6 pt-6 align-content-center justify-content-center">
            <Col xs="12" lg="10" xl="8">
              <p>Last updated: September 15, 2021</p>

              <p className="lead">
                bld.ai is committed to protecting your privacy. This Privacy
                Policy explains the privacy practices (data collection and
                usage) of bld.ai and the bld.ai Web3 Studio website. By using
                the website, you consent to the data practices described in this
                statement. Some bld.ai websites and apps may have different
                privacy policies. Please read the privacy notice of each side to
                understand how bld.ai uses your Personal Information.
              </p>

              <p>
                <b>Collection of your Personal Information.</b> In order to
                better provide you with products and services offered on our
                Site, bld.ai may collect personally identifiable information,
                such as your:
              </p>

              <ul>
                <li>First and Last Name;</li>
                <li>E-mail Address;</li>
                <li>Phone Number;</li>
                <li>Age; or</li>
                <li>Sex.</li>
              </ul>

              <p>
                We do not collect any personal information about you unless you
                voluntarily provide it to us. However, you may be required to
                provide certain personal information to us when you elect to use
                certain products or services available on the Site. These may
                include: (a) registering for an account on our Site; (b) signing
                up for special offers from selected third parties; (c) sending
                us an email message; (d) submitting your credit card or other
                payment information when ordering and purchasing products and
                services on our Site. To wit, we will use your information for,
                but not limited to, communicating with you in relation to
                services and/or products you have requested from us. We also may
                gather additional personal or non-personal information in the
                future.
              </p>

              <p>
                <b>Use of your Personal Information.</b> bld.ai collects and
                uses your personal information to operate its website(s) and app
                and deliver the services you have requested.
              </p>

              <p>
                bld.ai may also use your personally identifiable information to
                inform you of other products or services available from bld.ai
                and its affiliates.
              </p>
              <p>
                <b>Sharing Information with Third Parties.</b> bld.ai does not
                sell, rent or lease its customer lists to third parties.
              </p>
              <p>
                bld.ai may share data with trusted partners to help perform
                statistical analysis, send you email or postal mail, provide
                customer support, or arrange for deliveries. All such third
                parties are prohibited from using your personal information
                except to provide these services to bld.ai, and they are
                required to maintain the confidentiality of your information.
              </p>
              <p>
                bld.ai may disclose your personal information, without notice,
                if required to do so by law or in the good faith belief that
                such action is necessary to: (a) conform to the edicts of the
                law or comply with legal process served on bld.ai or the site;
                (b) protect and defend the rights or property of bld.ai; and/or
                (c) act under exigent circumstances to protect the personal
                safety of users of bld.ai, or the public.
              </p>
              <p>
                <b>Automatically Collected Information.</b> Information about
                your computer hardware and software may be automatically
                collected by bld.ai. This information can include: your IP
                address, browser type, domain names, access times and referring
                website addresses. This information is used for the operation of
                the service, to maintain quality of the service, and to provide
                general statistics regarding use of the bld.ai website.
              </p>
              <p>
                <b>Storing your Personal Data.</b> The personal data we collect
                from you is stored in the United States (Oregon) on Cloud
                Servers of Amazon Web Services.
              </p>
              <p>
                We will hold your personal data for as long as it is necessary
                in order to provide you with bld.ai services, deal with any
                specific issues that may arise or as it is required by law or by
                any other relevant regulatory body. Once you choose to close
                your account with bld.ai, we will delete the personal data
                relating to your account within 1 month.
              </p>
              <p>
                <b>Links.</b> This website contains links to other sites. Please
                be aware that we are not responsible for the content or privacy
                practices of such other sites. We encourage our users to be
                aware when they leave our site and to read the privacy
                statements of any other site that collects personally
                identifiable information.
              </p>
              <p>
                <b>Children Under Thirteen.</b> bld.ai does not knowingly
                collect personally identifiable information from children under
                the age of thirteen. If you are under the age of thirteen, you
                must ask your parent or guardian for permission to use this
                website.
              </p>
              <p>
                <b>E-mail Communications.</b> From time to time, bld.ai may
                contact you via email for the purpose of providing
                announcements, promotional offers, alerts, confirmations,
                surveys, and/or other general communication.
              </p>
              <p>
                <b>Data Protection Rights.</b> If you are a resident of the
                European Economic Area (EEA), you have certain data protection
                rights. If you wish to be informed what personal data we hold
                about you and if you want it to be removed from our systems,
                please contact us at <Obfuscate email="dpo.gdpr@bld.ai" />.
              </p>
              <p>
                In certain circumstances, you have the following data protection
                rights:
              </p>
              <ul>
                <li>
                  <p>
                    The right to access, update or to delete the information we
                    have on you
                  </p>
                </li>
                <li>
                  <p>The right of rectification</p>
                </li>
                <li>
                  <p>The right to object</p>
                </li>
                <li>
                  <p>The right of restriction</p>
                </li>
                <li>
                  <p>The right to data portability</p>
                </li>
                <li>
                  <p>The right to withdraw consent</p>
                </li>
              </ul>
              <p>
                <b>Privacy Information for California residents.</b> If you are
                a California resident, California law (California Consumer
                Privacy Act or CCPA) requires us to provide you with additional
                information regarding your privacy rights with respect to your
                personal information.
              </p>
              <p>
                bld.ai did not, during the preceding 12 months, do not currently
                and will not sell your personal information, unless you give us
                consent or direct us to do so.
              </p>
              <p>
                CCPA provides California residents with the following rights:
              </p>
              <ul>
                <li>
                  The right to request disclosure of any of your personal
                  information that we collected.
                </li>
                <li>
                  The right to request deletion of any of your personal
                  information that we collected.
                </li>
              </ul>
              <p>
                You can contact us with your concerns or requests by sending an
                email to <Obfuscate email="dpo.ccpa@bld.ai" />.
              </p>
              <p>
                <b>Changes to this Statement.</b> bld.ai reserves the right to
                change this Privacy Policy from time to time. We will notify you
                about significant changes in the way we treat personal
                information by sending a notice to the primary email address
                specified in your account, by placing a prominent notice on our
                site, and/or by updating any privacy information on this page.
                Your continued use of the Site and/or Services available through
                this Site after such modifications will constitute your: (a)
                acknowledgment of the modified Privacy Policy; and (b) agreement
                to abide and be bound by that Policy.
              </p>
              <p>
                <b>Contact Information.</b> bld.ai welcomes your questions or
                comments regarding this Statement of Privacy. If you believe
                that bld.ai has not adhered to this Statement, please contact
                bld.ai at <Obfuscate email="web3studio@bld.ai" />.
              </p>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
